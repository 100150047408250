<script lang="ts" setup>
  defineProps({
    reverse: {
      type: Boolean,
      default: false,
    },
    follow: {
      type: Boolean,
      default: false,
    },
  })

  const { find } = useStrapi()
  const i18n = useI18n()
  const { t, locale } = i18n
  const withFallback = ref(true)

  const fallbackSocial = computed(() => {
    return deepMerge(socials.value?.[i18n.defaultLocale], socials.value?.[locale.value]) || {}
  })

  // useAsyncData composable for language change
  const { data: socials } = await useAsyncData(async () => {
    try {
      const rawSocials = (await find('social', {
        locale: 'all' as any,
      })) as any

      const rawResult = {} as any
      rawSocials.data.forEach((item: any) => {
        const filteredObjects = filterObjectWithKeys(item?.attributes, ['createdAt', 'updatedAt', 'locale', 'localizations'])
        rawResult[item?.attributes?.locale] = filteredObjects
      })
      return rawResult
    } catch (error) {}
  })
</script>

<template>
  <ul class="social-list">
    <template v-for="(socialUrl, social) in withFallback ? fallbackSocial : socials?.[locale] || socials?.en" :key="social">
      <li class="social-item">
        <a class="social-link" :href="socialUrl" target="_blank">
          <img class="social-icon" :src="`/images/social-media/${social}.svg`" height="19" :alt="social as any" loading="lazy" />
        </a>
      </li>
    </template>
  </ul>
</template>

<style lang="scss">
  .social {
    width: fit-content;

    &-list {
      display: flex;
      align-items: center;
      gap: 0.75rem 0.5rem;
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      padding: 0.5rem;
      border-radius: 50%;
      background-color: #fff;
      color: #2e2e2e;
      font-size: 17px;
      &:hover {
        background-color: #263a75;
        .social {
          &-icon {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
</style>
