export const useFooterHeight = () => {
  const footerHeight = ref()
  function setFooterHeight() {
    const footer = document.querySelector('.footer') as HTMLElement
    if (!footer) return
    footerHeight.value = footer.offsetHeight
    document.documentElement.style.setProperty('--footer-height', footerHeight.value + 'px')
  }
  onBeforeMount(() => {
    setFooterHeight()
    window?.addEventListener('resize', setFooterHeight)
  })
  onBeforeUnmount(() => {
    window?.addEventListener('resize', setFooterHeight)
  })
}
