export const useHeaderHeight = () => {
  const headerHeight = ref()

  function setHeaderHeight() {
    const header = document.querySelector('.header') as HTMLElement
    if (!header) return
    headerHeight.value = header.offsetHeight
    document.documentElement.style.setProperty('--header-height', headerHeight.value + 'px')
  }
  onBeforeMount(() => {
    setHeaderHeight()
    window?.addEventListener('resize', setHeaderHeight)
  })

  onBeforeUnmount(() => {
    window?.addEventListener('resize', setHeaderHeight)
  })
  return headerHeight
}
